@font-face {
  font-family: 'DeDaFont';
  src: url('/public/fonts/Rooftop -Regular-Web.woff2') format('woff2'),
       url('/public/fonts/Rooftop -Regular-Web.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: DeDaFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #2a0184; */
  /* height: 2400px; */
  overflow-x: hidden;
}
.body-section{
  max-width: 1600px;
  margin: auto;
}
.mid-img{
  z-index: -1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  filter: grayscale(100%);
  mask-image: linear-gradient(to top,  rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
      
}
header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-image: linear-gradient(to right, #3700AF, #010101);
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
/* header::before{
  display: block;
  position: relative;
  content: '';
  width: 10%;
  top: 15%;
  height: 70%;
  background-color: white;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
} */
.header-space{
  height: 100px;
}
.section .logo {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  margin-left:-25px;
}
header .section {
  display: flex;
  align-items: center;
}
.section a {
  text-decoration: none;
  color: #000000;
  margin: 0 15px;
}

header .social-icons a {
  margin-left: 10px;
  text-decoration: none;
  color: #66fcf1;
}
header .social-icons-head a {
  margin-left: 10px;
  text-decoration: none;
  color: #66fcf1;
}
.section-links{
  color: white;
  background-color: white;
  background: url("/public/curve2.png");
    background-size: cover;
    background-position-y: 69%;
    background-repeat: no-repeat;
    height: 43px;
    margin-top: -26px;
    padding-top: 35px;
    padding-left: 72px;
    padding-right: 45px;
    height: 37px;
    margin-top: -26px;
    padding-top: 35px;
    padding-left: 64px;
    padding-right: 45px;

}
.main-section{
  display: flex;
  background-image: linear-gradient(to right, #3700AF, #010101);
  margin-left: 16px;
  margin-right: 16px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding-bottom: 100px;
}

.intro {
  text-align: left;
  margin-left: 150px;
  justify-content: right;
  flex-wrap:wrap;
  margin-top: 100px;
  width: 45%;
}

.intro h1 {
  font-size: 5em;
  margin: 0;
 color: #EFBD65;
}

.intro h2 {
  font-size: 4.5em;
  margin: 0;
  color: white;
}

.intro h3 {
  font-size:3.1em;
  margin: 0;
  color: white;
}

.transactions {
  display: flex;

  width: 420px;
  /* margin-right: 80px; */
  justify-content: right;
  color: #fff;
}

.transaction-panel {

  /* background: rgb(12, 1, 36,0.1); */
  background-image: linear-gradient(#000000, #3700AF);
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 15px #fff;
    -moz-box-shadow: inset 0 0 15px #fff;
    box-shadow: inset 0 0 15px #fff;
  width: 400px;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  border: 1px solid #8a8aa0;
  border-radius: 24px;
}

.panel-header button {

  width: 100%;
  border: none;
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding: 14px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.previous-purchases {
  margin-top: 20px;
}

.previous-purchases h3 {
  margin: 0 0 10px 0;
}

.purchase-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.purchase-item .tether {
  color: #66fcf1;
}

.sell-buy-section {
  margin-top: 20px;
  position: relative;
}

.sell-buy-section h3 {
  margin: 0 0 10px 0;
}

.sell-buy-section input {
  width: 91%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #8a8aa0;
  border-radius: 5px;
  background: #26262f;
  padding: 15px;
  color: #c5c6c7;
}

.sell-buy-section p {
  margin: 0 0 10px 0;
}

.sell-buy-section .sell-button {
  width: 100%;
  background: #EFBD65;
  border: none;
  padding: 10px;
  color: #000000;
  cursor: pointer;
  padding: 15px;
  border-radius: 30px;
}

.max-button {
  position:absolute;
  right: 10px;
  background: transparent;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 30px;
}
.available-amount {
  position: absolute;
  right: 10px;
  font-size: 0.7em;
  color: #c5c6c7;
}

.price-text {
  font-size: 0.8em;
  padding-bottom: 20px;
}
.what-is-hedge90{
  margin: 50px auto;
  font-size: 1.2em;
  width: 44%;
  color: white;
  border: 1px solid #8a8aa0;
  border-radius: 50px;
  background-image: linear-gradient(#3700AF,#000000);
  /* background-color: rgba(0, 0, 0, 0.61); */

  padding: 40px;
  text-align: center;
  backdrop-filter: blur(5px);
}

.how-to-buy {
  margin: 0 auto;
  width: 44%;
  color: white;
  border: 1px solid #8a8aa0;
  border-radius: 50px;
  background-image: linear-gradient(#3700AF,#000000);
    
  backdrop-filter: blur(5px);

}

.section-title{
  text-align: center;
  font-size: 2.5em;
  padding: 30px;
  font-weight: bold;
}

.secion-step{
  font-size: 1.5em;
  font-weight: bold;
  padding-left: 100px;

  color: #EFBD65;
}

.section-step-title {
  font-size: 2em;
  font-weight: bold;
  padding-left: 100px;
  padding-top: 30px;
  color: #ffffff;
}


.section-step-content {
  font-size: 1.2em;
  padding-left: 100px;
  padding-top: 30px;
  padding-bottom: 70px;
  color: #c5c6c7;
  width: 80%;
}

.key-feature-title{
  font-size: 2.6em;
  font-weight: bold;
  padding-left: 110px;
  padding-top: 30px;
  color: #000000;
}

.key-features{
  color: white;
  margin-left: 100px;
  margin-top: 50px;
  display: flex;
}

.key-feature-section-box{
  width: 30%;
  min-height: 280px;
  margin: 10px;
  display:flexbox;
  border: 1px solid #EFBD65;
  border-radius: 10px;
  background-color: #EFBD65;
  backdrop-filter: blur(5px);
  /* box-sizing: border-box;
    clip-path: polygon(
      0 0,
      calc(100% - 50px) 0,
      100% 50%,
      calc(100% - 50px) 100%,
      0 100%
    ); */
}
.key-feature-section-box::before {
  content: "";
  position: absolute;
  height: 200px;
  top:20%;
  left: 90%;
  right: -60%;
  border-radius: 50%;
  /* bottom: -62px; */
  background: #ffffff;
  z-index: 1;
}

.key-feature-section-box-title{
  font-size: 2em;
  font-weight: bold;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 10px;
}

.key-feature-section-box-content{
  font-size: 1.2em;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 70px;
  color: #ffffff;
  width: 80%;
}
.key-feature-wide-box{
  z-index:100;
  width: 60%;
  min-height: 280px;
  margin: 10px;
  display:flex;
  flex-wrap: wrap;
  border: 1px solid #EFBD65;
  border-radius: 10px;
  background-color: #3700AF;
  backdrop-filter: blur(5px);
}
.key-feature-wide-box-title{
  font-size: 2em;
  font-weight: bold;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 10px;
  width: 30%;
  flex-grow: 1;
}
.key-feature-wide-box-conent{
  font-size: 1.2em;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 70px;
  color: #ffffff;
  width: 30%;
  flex-grow: 1;
}
.empty-box{
  width: 30%;
}
.key-feature-wide-box-img{
  width: 30%;
  float: right;
  display:inline-bloc;
}

.faq-section{
  margin-top: 100px;
}

.faq-title{
  margin-left: 100px;
  font-size: 2.5em;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.faq-sub-title {
  margin-left: 100px;
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 1.4em;
  color: white;
}
.faq-answer {
  line-height: 1.5em;
}

.collapsible {
  color: white;
  /* background-color: rgb(38 38 47); */
  background-color: #ededed;
  border: 1px solid rgb(240, 240, 255);
  border-radius: 10px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 20px;
}

.collapsible-header {
  background-color:#ededed; 
  font-size: 1.1em;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 30px;
}

.collapsible-content {
  color: #8a8aa0;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;

  display: none;
  overflow: hidden;
  transition: height 2s ease-in-out;
}

.collapsible-content.open {
  display: block;
}

.footer-section {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: auto;
  margin-top: 100px;
  background-image: linear-gradient(to right, #3700AF, #010101);
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 30px;
}

.footer-parent{
  background-image: linear-gradient(to right, #3700AF, #010101);
  width: 90%;
  margin: 0 auto;
  border-radius: 30px;
}

.copy-contact-section {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  padding: 20px;
}

.copyright{
  color: white;
}
.contact-us{
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.inner-modal{
  width: 360px;
  margin:100px auto;
  align-content:center
}

.modal-content {
   background:#26262f;
  text-align: "center";
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10%;
  margin-right: auto;
  margin-left: auto;
}



.button-container {
  width: 210px;
  margin: 0 auto;
}

.savable-btn {
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  background:#EFBD65;
  border-radius: 5px;
}

.purchase-checkmark:after {
  content: '✔';
  position: absolute;
  margin-left: 150px;
  margin-top: -30px;
  width: 20px; 
  height: 20px;
  text-align: center;
  border: 1px solid #00ff6a;
  background: #000000;
  color: #00ff6a;
  border-radius: 50%;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.3)
}

.return-checkmark:after {
  content: 'x';
  position: absolute;
  margin-left: 150px;
  margin-top: -30px;
  width: 23px; 
  height: 23px;
  text-align: center;
  border: 1px solid #ff0000;
  background: #000000;
  color: #ff0000;
  border-radius: 50%;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.3)
}

.arrow {
  position: absolute;
  right: 60px;
  width: 18px;
  display: inline;
}
.bar {
  list-style: none;
  position: absolute;
}
.bar >li {
  position: relative;
  color: #f4e1c1;
  line-height: 12;
  top:-120px
}
.bar>li:before {
  content: '\25CF';
  margin-right: 10px;
  font-size: 20px;
}
.bar>li:after {
  position: absolute;
  left: 2.5px;
  top: 0px;
  content: '';
  border-left: 2px solid  #f4e1c1;
  margin-left: 5px;
  height: 100%;
  
}
.bar >li:first-of-type:after {
  top: 50%;
}
.bar >li:last-of-type:after {
  top: -50%;

}
.choice-box{
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.choice-box-child {
  background-color: rgb(255 255 255);
  color:black;
  width: 46%;
  border: 1px solid black;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.choice-flex{
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 1270px) {
  .how-to-buy {
    width: 80%;
  }

  .secion-step{
    padding-left: 100px;
  }
  
  .section-step-title {
    padding-left: 100px;
    padding-right: 100px;
  }
  
  
  .section-step-content {
    font-size: 1.2em;
    padding-left: 100px;
    padding-top: 30px;
    padding-bottom: 70px;
    padding-right: 100px;
    color: #c5c6c7;
    width: 80%;
  }
}

@media screen and (max-width: 1110px){
  .key-feature-wide-box-img{
    display: none;
  }
  .empty-box{
    display: none;
  }
}

@media screen and (max-width: 982px) {
  .section-links{
    color: white;
    background: none;
    margin: 0;
    padding: 0;
    /* margin-top: -26px;
    padding-top: 80px;
    padding-left: 72px; */
  }
  .section-links a{
    color: white;
  }
}
@media screen and (max-width: 1015px){
  .intro{
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 960px) {
  
  .intro h1 {
    font-size: 2.7em;
  }
  
  .intro h2 {
    font-size: 2.5em;
  }

  .key-features{
    display: block;
  }
  .key-feature-section-box{
    width: 80%;
  }

  .for-large-screen{
    display: none;
  }
  .key-feature-wide-box{
    width: 80%;
  }
  .key-feature-wide-box-title{
    font-size: 1.2em;
  }
  .key-feature-wide-box-conent{
    font-size: 0.7em;
  }
}

@media screen and (max-width: 800px) {
  .choice-flex{
    display: block;
  }
  .choice-box-child{
    width: 100%;
  }
  .main-section {
    display: ruby-text;
  }
  .transactions{
    margin: 2%;
  }
  .intro {
    display:none;
  }
  .section-links {
    display: none;
  }
}

/* @media screen and (max-width: 882px) {
  .section-links{
    font-size: 0.8em;
  }
  .section .logo {
    font-size: 1em;
  }
} */

@media screen and (max-width: 740px) {
  .bar{
    display: none;
  }
  .how-to-buy {
    width: 80%;
  }

  .secion-step{
    padding-left: 50px;
  }
  
  .section-step-title {
    padding-left: 50px;
    padding-right: 50px;
  }
  
  
  .section-step-content {
    font-size: 1.2em;
    padding-left: 50px;
    padding-top: 30px;
    padding-bottom: 70px;
    padding-right: 50px;
    color: #c5c6c7;
    width: 80%;
  }
  
}


@media screen and (max-width: 560px) {
  .transactions {
    width: 320px;
    margin: auto;
    line-height: 0.5;
  }
  .sell-buy-section input {
    width: 88%;
  }
  .available-amount{
    position:initial;
  }
  .price-text{
    padding-top: 10px;
  }
  .section-title {
    font-size: 1em;
    padding: 15px;
  }
  .section-step-title{
    font-size: 1.3em;
    padding-top: 15px;
  }
  .section-step-content{
    font-size: 0.7em;
    width: 50%;
    padding-top: 15px;
    padding-bottom: 30px;
  }
  .key-feature-title{
    font-size: 1.5em;
    padding-left: 50px;
  }
  .key-features {
    margin-left: 30px;
  }
  .key-feature-section-box-title {
    font-size: 1.5em;
  }
  .key-feature-section-box-content{
    font-size: 1em;
  }
  .faq-title {
    margin-left: 30px;
    font-size: 1.5em;
    color: black
  }
  .faq-sub-title {
    font-size: 1em;
    margin-left: 30px;
    width: 80%;
  }
  .collapsible {
    margin-left: 30px;
    margin-right: 30px
  }
  .collapsible-content{
    padding-left: 15px;
    padding-right: 10px
  }
  .faq-answer {
    line-height: 1.1em;
  }
  .social-icons{
    width: 50px;
  }
  .arrow {
    right: 25px;
    width: 14px;
  }
  .what-is-hedge90{
    font-size: 0.7em;
  }
}

@media screen and (max-width: 518px) {
  .social-icons-head{
    display: none;
  }
}
@media screen and (max-width: 320px) {
  .inner-modal {
    transform: scale(0.6);
    margin:-40px auto
  }
}

iframe
{
    display: none;
}